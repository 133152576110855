.mat-slide-toggle.mat-checked:not([class*='default']) .mat-slide-toggle-thumb {
  background-color: mat-color($neutral, 700);
}

.mat-slide-toggle.mat-checked:not([class*='default']) .mat-slide-toggle-bar {
  background-color: mat-color($neutral, 300);
}

.mat-slide-toggle-bar:not([class*='default']) {
  background-color: mat-color($neutral, 100);
}

.mat-slide-toggle-thumb:not([class*='default']) {
  background-color: mat-color($neutral, 50);
}

.mat-slider-ticks:not([class*='default']) {
  background-color: mat-color($neutral, 200);
}
