@import 'styles/new-design/base/layout.scss';
@import 'styles/new-design/variables/_index';
@import 'styles/new-design/mixins/_index';

mat-nested-tree-node .mat-tree-node {
  .mat-button.mat-primary,
  .mat-icon-button.mat-primary,
  .mat-stroked-button.mat-primary .mat-stroked-button.mat-focus-overlay {
    color: mat-color($neutral, 900);
    background-color: mat-color($neutral, 50);
  }
}

mat-nested-tree-node .mat-tree-node:hover {
  .mat-button.mat-primary:hover,
  .mat-icon-button.mat-primary:hover,
  .mat-stroked-button.mat-primary:hover {
    background-color: mat-color($neutral, 100);
  }
}

.tree-container .mat-button {
  color: mat-color($neutral, 900);
}

.mat-stroked-button .mat-focus-overlay,
.mat-button.mat-primary .mat-button-focus-overlay {
  display: none;
}
