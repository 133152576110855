@use 'styles/new-design/variables/colors' as colors;

.mat-button-toggle:not([class*='default']) {
  background-color: mat-color(colors.$neutral, 50);
}

.mat-button-toggle-checked:not([class*='default']) {
  background-color: mat-color(colors.$neutral, 200);
  color: mat-color(colors.$neutral, 900);
}
