@import 'styles/new-design/mixins/elevation';

.mat-raised-button:not([class*='mat-elevation-z']) {
  @include elevation(3);
}
.mat-raised-button:not([disabled]):active:not([class*='mat-elevation-z']) {
  @include elevation(10);
}

.mat-card:not([class*='mat-elevation-z']),
.mat-expansion-panel:not([class*='mat-elevation-z']) {
  @include elevation(1);
}
mat-tab-body .mat-card:not([class*='mat-elevation-z']) {
  box-shadow: none;
}

.mat-fab:not([class*='mat-elevation-z']),
.mat-mini-fab:not([class*='mat-elevation-z']) {
  @include elevation(10);
}

.mat-fab:not([disabled]):active:not([class*='mat-elevation-z']),
.mat-mini-fab:not([disabled]):active:not([class*='mat-elevation-z']) {
  @include elevation(20);
}

.mat-snack-bar-container:not([class*='mat-elevation-z']) {
  @include elevation(10);
}

@mixin mat-elevation-classes {
  @for $i from 0 through 24 {
    .mat-elevation-z#{$i} {
      @include elevation($i);
    }
  }
}

@include mat-elevation-classes;
