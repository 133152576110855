@import 'styles/new-design/variables/_index';
.mat-select-arrow:not([class*='default']) {
  border: none;
  margin-bottom: -12px;
  margin-top: -12px;
  height: auto;
  width: auto;
}

.mat-select-arrow::before {
  @extend .material-icons;
  content: 'expand_more';
}

.mat-select-search-inside-mat-option:not([class*='default'])
  button.mat-select-search-clear:not([class*='default']) {
  padding: 0;
  border-radius: 50%;
  top: 1px;
}

.mat-select-panel:not([class*='default']) {
  max-width: 80%;
}

::ng-deep .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: unset;
}

::ng-deep .mat-option-pseudo-checkbox.mat-pseudo-checkbox-checked {
  background-color: mat-color($neutral, 600);
}

.mat-option.mat-selected {
  color: mat-color($neutral, 300);
}

.mat-primary
  .mat-option.mat-selected:not(.mat-option-disabled):not([class*='default']) {
  color: mat-color($neutral, 900);
  background-color: mat-color($neutral, 50);
}

.mat-pseudo-checkbox.mat-pseudo-checkbox-checked:not([class*='default']) {
  background-color: mat-color($neutral, 600);
}
