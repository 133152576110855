@use 'styles/new-design/variables/colors' as colors;

.mat-badge-content {
  color: mat-color(colors.$info, A100);
}
.mat-badge-info .mat-badge-content:not([class*='default']) {
  background: mat-color(colors.$info);
}
.mat-badge-success .mat-badge-content:not([class*='default']) {
  background: mat-color(colors.$success);
}
