@import '../variables/_index.scss';

.no-margin {
  margin: 0 !important;
}
.no-margin-top {
  margin-top: 0 !important;
}
.no-margin-bottom {
  margin-bottom: 0 !important;
}
.padding {
  padding: 1 * $spacing;
}

.padding-top {
  padding-top: 1 * $spacing;
}

.no-padding {
  padding: 0 !important;
}

.no-padding-bottom {
  padding-bottom: 0 !important;
}

.mini-padding {
  padding: 0.5 * $spacing;
}
.no-min-height {
  min-height: 0;
}
.normal-white-space {
  white-space: normal !important;
}
