@use 'styles/new-design/variables/spacing' as spacing;
@use '@angular/material' as mat;
@use 'styles/new-design/mixins/gradients' as gradients;

.mat-button.mat-button-base,
.mat-flat-button.mat-button-base,
.mat-raised-button.mat-button-base,
.mat-stroked-button.mat-button-base {
  white-space: nowrap;
  text-transform: none;
  border-radius: spacing.$borderRadius;

  &[disabled] {
    opacity: 0.8;
  }

  & mat-progress-spinner {
    display: inline-block;
  }
}

.mat-raised-button.mat-accent:focus,
.mat-raised-button.mat-accent:hover {
  transition: none;
  background: mat.get-color-from-palette($neutral, 600);
}
.mat-icon-button:hover {
  transition: none;
  background: rgba(map_get($neutral, 900), 0.1);
}

.mat-button.mat-accent,
.mat-icon-button.mat-accent,
.mat-stroked-button.mat-accent {
  color: mat.get-color-from-palette($neutral, 900);
}
.mat-stroked-button.mat-button-base {
  border-color: inherit;
}

.mat-flat-button.mat-accent,
.mat-raised-button.mat-accent,
.mat-fab.mat-accent,
.mat-mini-fab.mat-accent {
  @include gradients.inclined-gradient();
  color: mat.get-color-from-palette($neutral, default-contrast);
  mat-progress-spinner circle {
    stroke: mat.get-color-from-palette($neutral, default-contrast);
  }
  &[disabled] {
    color: mat.get-color-from-palette($neutral, default-contrast);
    opacity: 0.4;
  }
}

.mat-flat-button.mat-primary,
.mat-raised-button.mat-primary,
.mat-fab.mat-primary,
.mat-mini-fab.mat-primary {
  @include gradients.inclined-gradient($neutral);
  color: mat.get-color-from-palette($neutral, default-contrast);
  mat-progress-spinner circle {
    stroke: mat.get-color-from-palette($neutral, default-contrast);
  }
  &[disabled] {
    color: mat.get-color-from-palette($neutral, default-contrast);
    opacity: 0.4;
  }
}

.mat-flat-button.mat-warn,
.mat-raised-button.mat-warn,
.mat-fab.mat-warn,
.mat-mini-fab.mat-warn {
  @include gradients.inclined-gradient($warning);
  mat-progress-spinner circle {
    stroke: mat.get-color-from-palette($neutral, default-contrast);
  }
  &[disabled] {
    color: mat.get-color-from-palette($warning, default-contrast);
    opacity: 0.4;
  }
}
.mat-calendar-body-selected {
  color: mat.get-color-from-palette($neutral, default-contrast);
}
