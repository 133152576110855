@use 'sass:math';

@import '../variables';
@import './media-queries';

@mixin actions-container() {
  background: map_get($neutral, 50);
}

$pageTopSpacing: 1 * $spacing;
$pageBottomSpacing: 2 * $spacing;
$pageSideSpacing: 2 * $spacing;
$pageSideSpacingSM: 1.5 * $spacing;
$pageSideSpacingXS: 1 * $spacing;

@mixin page-spacing($isPadding: true, $onlyHorizontal: false) {
  $type: 'margin';
  @if $isPadding {
    $type: 'padding';
  }

  @if $onlyHorizontal == false {
    #{$type}-top: $pageTopSpacing;
    #{$type}-bottom: $pageBottomSpacing;
  }

  #{$type}-left: $pageSideSpacing;
  #{$type}-right: $pageSideSpacing;

  @include media-sm {
    #{$type}-left: $pageSideSpacingSM;
    #{$type}-right: $pageSideSpacingSM;
  }

  @include media-xs {
    #{$type}-left: $pageSideSpacingXS;
    #{$type}-right: $pageSideSpacingXS;
  }
}

@mixin aspect-ratio($width, $height, $contentSelector: '.content') {
  position: relative;
  &:before {
    display: block;
    content: '';
    width: 100%;
    padding-top: math.div($height, $width) * 100%;
  }
  > #{$contentSelector} {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
  }
}
