@import '../typography';

.mat-card-title:not([class*='default']) {
  @include h4();
  margin-bottom: 1 * $spacing;
}

.mat-card-subtitle:not([class*='default']) {
  font-size: inherit;
  color: mat-color($neutral, 700);
  margin-bottom: 1 * $spacing;
}

.mat-card-title:not(:first-child):not([class*='default']),
.mat-card-subtitle:not(:first-child):not([class*='default']) {
  margin-top: -0.75 * $spacing;
}

.mat-card-image:first-child:not([class*='default']) {
  margin-top: -1 * $spacing;
}

.mat-card-actions:not([class*='default']) {
  display: grid;
}

.mat-card-actions button:not([class*='default']) {
  padding-left: 0.5 * $spacing;
  padding-right: 0.5 * $spacing;
}
