@use 'styles/new-design/mixins/media-queries' as media;
@use 'styles/new-design/variables/colors' as colors;
@use 'styles/new-design/variables/spacing' as spacing;
@use '@angular/material' as mat;

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  color: mat.get-color-from-palette(colors.$neutral, 900);
  font-size: 0.875rem;
  line-height: 1.57;
}

a {
  color: mat.get-color-from-palette(colors.$primary);
  cursor: pointer;
  text-decoration: underline;
}

a.no-underline {
  cursor: pointer;
  text-decoration: none !important;
}

*:not(.mat-list-item-content)
  > a[href*='http']:not(.mat-button-base):not(.mat-list-item):not([href*='map']) {
  position: relative;
  padding-right: 0.8 * spacing.$spacing;

  &::after {
    position: absolute;
    font-family: 'Material Icons';
    content: '\e89e';
    color: mat.get-color-from-palette(colors.$neutral, 900);
    padding-left: 0.1 * spacing.$spacing;
  }

  &.no-icon {
    padding-right: 0;
    &::after {
      display: none;
    }
  }
}

strong {
  font-weight: 500;
}

h1:not([class*='default']),
.h1,
h2:not([class*='default']),
.h2,
h3:not([class*='default']),
.h3,
h4:not([class*='default']),
.h4,
h5:not([class*='default']),
.h5,
h6:not([class*='default']),
.h6 {
  line-height: 1;
}

h1:not([class*='default']),
.h1 {
  font-size: 1.875rem;
  font-weight: 500;

  @include media.media-xs {
    font-size: 1.5;
    font-weight: 400;
  }
}

// this is used to position stepper-sidebar
$h2FontSize: 1.5rem;
$h2FontSizeXS: 1.5rem;
h2:not([class*='default']),
.h2 {
  font-size: $h2FontSize;
  font-weight: 500;

  @include media.media-xs {
    font-size: 1.5rem;
  }
}

h3:not([class*='default']),
.h3 {
  font-size: 1.25rem;
  font-weight: 400;

  @include media.media-xs {
    font-size: 1rem;
  }
}

// this is used to style mat card title
@mixin h4() {
  font-size: 1rem;
  font-weight: 500;

  @include media.media-xs {
    font-size: 0.875rem;
  }
}

h4:not([class*='default']),
.h4 {
  @include h4();
}

h5:not([class*='default']),
.h5 {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: inherit;

  @include media.media-xs {
    font-size: 0.875rem;
    font-weight: normal;
    text-decoration: underline;
  }
}

h6:not([class*='default']),
.h6 {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.65rem;
  color: mat.get-color-from-palette(colors.$neutral, 500);
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small,
.h1 small,
.h2 small,
.h3 small,
.h4 small,
.h5 small,
.h6 small,
h1 .small,
h2 .small,
h3 .small,
h4 .small,
h5 .small,
h6 .small,
.h1 .small,
.h2 .small,
.h3 .small,
.h4 .small,
.h5 .small,
.h6 .small {
  color: mat.get-color-from-palette(colors.$neutral, 700);
}

h1 small,
.h1 small,
h2 small,
.h2 small,
h3 small,
.h3 small,
h1 .small,
.h1 .small,
h2 .small,
.h2 .small,
h3 .small,
.h3 .small {
  font-size: 65%;
}

h4 small,
.h4 small,
h5 small,
.h5 small,
h6 small,
.h6 small,
h4 .small,
.h4 .small,
h5 .small,
.h5 .small,
h6 .small,
.h6 .small {
  font-size: 70%;
}

small:not([class*='default']),
.small {
  font-size: 85%;
}

.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.text-bold {
  font-weight: 500;
}
