$_default-radius: 45px;
$_default-circumference: 3.14 * $_default-radius * 2;

.pd-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  svg {
    transform: rotate(-90deg);
    transform-origin: center;
    overflow: visible;
    animation: mat-progress-spinner-linear-rotate 2s linear infinite;
    width: 100px;
    height: 100px;

    circle {
      fill: rgba(0, 0, 0, 0);
      transition: stroke-dashoffset 225ms linear;
      transition-property: stroke;
      stroke: #5d6a72;
      stroke-dasharray: 282.743px;
      stroke-width: 10%;
      transform-origin: 50% 50%;
      animation-name: mat-progress-spinner-stroke-rotate-100;
      animation-duration: 4s;
      animation-timing-function: cubic-bezier(0.35, 0, 0.25, 1);
      animation-iteration-count: infinite;
    }
  }
}

@keyframes mat-progress-spinner-linear-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@at-root {
  $start: (1 - 0.05) * $_default-circumference; // start the animation at 5%
  $end: (1 - 0.8) * $_default-circumference; // end the animation at 80%

  @keyframes mat-progress-spinner-stroke-rotate-100 {
    0% {
      stroke-dashoffset: $start;
      transform: rotate(0);
    }
    12.5% {
      stroke-dashoffset: $end;
      transform: rotate(0);
    }
    12.5001% {
      stroke-dashoffset: $end;
      transform: rotateX(180deg) rotate(72.5deg);
    }
    25% {
      stroke-dashoffset: $start;
      transform: rotateX(180deg) rotate(72.5deg);
    }

    25.0001% {
      stroke-dashoffset: $start;
      transform: rotate(270deg);
    }
    37.5% {
      stroke-dashoffset: $end;
      transform: rotate(270deg);
    }
    37.5001% {
      stroke-dashoffset: $end;
      transform: rotateX(180deg) rotate(161.5deg);
    }
    50% {
      stroke-dashoffset: $start;
      transform: rotateX(180deg) rotate(161.5deg);
    }

    50.0001% {
      stroke-dashoffset: $start;
      transform: rotate(180deg);
    }
    62.5% {
      stroke-dashoffset: $end;
      transform: rotate(180deg);
    }
    62.5001% {
      stroke-dashoffset: $end;
      transform: rotateX(180deg) rotate(251.5deg);
    }
    75% {
      stroke-dashoffset: $start;
      transform: rotateX(180deg) rotate(251.5deg);
    }

    75.0001% {
      stroke-dashoffset: $start;
      transform: rotate(90deg);
    }
    87.5% {
      stroke-dashoffset: $end;
      transform: rotate(90deg);
    }
    87.5001% {
      stroke-dashoffset: $end;
      transform: rotateX(180deg) rotate(341.5deg);
    }
    100% {
      stroke-dashoffset: $start;
      transform: rotateX(180deg) rotate(341.5deg);
    }
  }
}
