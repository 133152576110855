@import '../../variables';
@import '../../mixins';

.info,
table.mat-table .info {
  @include gradient($info);
  color: mat-color($info, default-contrast);
}

.info-text,
table.mat-table .info-text {
  color: mat-color($info) !important;
}

.success,
table.mat-table .success {
  @include gradient($success, 600, 700);
  color: mat-color($success, default-contrast);
}

.success-text,
table.mat-table .success-text {
  color: mat-color($success, 600) !important;
}

.primary-text,
table.mat-table .primary-text {
  color: mat-color($primary, 600) !important;
}

.warning,
table.mat-table .warning {
  @include gradient($warning);
  color: mat-color($warning, default-contrast);
}

.warning-text,
table.mat-table .warning-text {
  color: mat-color($warning) !important;
}

.error,
table.mat-table .error {
  @include gradient($error, 600, 700);
  color: mat-color($error, default-contrast);
}

.error-text,
table.mat-table .error-text {
  color: mat-color($error) !important;
  font-weight: 500;
}

table.mat-table .error-text,
table.mat-table .warning-text,
table.mat-table .success-text,
table.mat-table .info-text {
  font-weight: 500;
}

.neutral,
table.mat-table .neutral {
  @include gradient($neutral);
  color: mat-color($neutral, default-contrast);
}

mat-icon[mini-icon] {
  font-size: 18px;
  width: 18px;
  height: 18px;
}
