@import 'styles/new-design/variables/_index';

.mat-list-base .mat-list-item {
  font-size: inherit;
}

.mat-list-base {
  padding-top: $spacing;
}

.mat-list-base .mat-list-item:not([class*='default']),
.mat-list-base .mat-list-option:not([class*='default']) {
  text-decoration: none;
  height: 2 * $spacing;
}

.mat-list-base .mat-list-item .mat-list-item-content:not([class*='default']),
.mat-list-base .mat-list-option .mat-list-item-content:not([class*='default']) {
  padding: 0 $spacing;
}
